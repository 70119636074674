<template>
  <LcScratchcardTeaser
    v-bind="{ to, badge, backgroundPattern }"
    :aria-label="name"
    :button-aria-label="
      $t('scratchcard.teaser.button.scratchnow.arialabel', {
        scratchcard: name,
      })
    "
    :button-text="$t('scratchcard.teaser.button.scratchnow')"
    :style="theme"
    :variant="campaignTeaserVariant"
    @primary-button-click="addBonusCode"
  >
    <template #image>
      <ScratchcardDisplay
        v-bind="{ fetchpriority, name, price }"
        :height="190"
        :is-caption-visible="!teaserSrc"
        :is-price-visible="!teaserSrc && !!price"
        :jackpot="amount"
        :primary-color="visualParameters?.primaryColor"
        :secondary-color="visualParameters?.secondaryColor"
        :src="teaserSrc"
        :width="190"
        @image-click="addBonusCode"
      />
    </template>
  </LcScratchcardTeaser>
</template>

<script lang="ts" setup>
import type { LcImageProps } from '@lottocom/frontend-components'

import type {
  CampaignPayload,
  Money,
  ScratchcardVisualParameters,
} from '~/@types/generated/backend/graphql-schema-types'

export type ScratchcardTeaserProps = Pick<LcImageProps, 'fetchpriority'> & {
  amount?: Maybe<Money>
  campaign?: Maybe<CampaignPayload>
  name: string
  price?: Maybe<Money>
  slug: string
  visualParameters?: Maybe<Omit<ScratchcardVisualParameters, 'scratchArea'>>
}

const props = defineProps<ScratchcardTeaserProps>()

const { toUrl } = useClientUrl()

// TODO SF-4090 remove after rollout
const teaserSrc = computed(() => {
  if (props.visualParameters?.teaserImage) {
    return toUrl(props.visualParameters?.teaserImage)
  }
  if (props.visualParameters?.teaserImageUrl) {
    return toUrl(props.visualParameters?.teaserImageUrl)
  }
  return null
})
const to = computed(() => ({ path: `/scratchcard/${props.slug}` }))

const theme = computed(() => ({
  '--scratchcard-teaser-frame-color': `${props.visualParameters?.titleColor ?? ''}`,
}))

const { campaign } = toRefs(props)
const { addBonusCode, backgroundPattern, badge, campaignTeaserVariant } =
  useOnsiteMarketingTeaser(campaign)
</script>
